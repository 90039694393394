// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-openfit-bundle-product-page-bundle-product-page-tsx": () => import("./../../../src/templates/openfit/BundleProductPage/BundleProductPage.tsx" /* webpackChunkName: "component---src-templates-openfit-bundle-product-page-bundle-product-page-tsx" */),
  "component---src-templates-openfit-cart-page-cart-page-tsx": () => import("./../../../src/templates/openfit/CartPage/CartPage.tsx" /* webpackChunkName: "component---src-templates-openfit-cart-page-cart-page-tsx" */),
  "component---src-templates-openfit-collection-page-collection-page-tsx": () => import("./../../../src/templates/openfit/CollectionPage/CollectionPage.tsx" /* webpackChunkName: "component---src-templates-openfit-collection-page-collection-page-tsx" */),
  "component---src-templates-openfit-home-page-home-page-tsx": () => import("./../../../src/templates/openfit/HomePage/HomePage.tsx" /* webpackChunkName: "component---src-templates-openfit-home-page-home-page-tsx" */),
  "component---src-templates-openfit-landing-page-landing-page-tsx": () => import("./../../../src/templates/openfit/LandingPage/LandingPage.tsx" /* webpackChunkName: "component---src-templates-openfit-landing-page-landing-page-tsx" */),
  "component---src-templates-openfit-product-page-product-page-tsx": () => import("./../../../src/templates/openfit/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---src-templates-openfit-product-page-product-page-tsx" */),
  "component---src-templates-openfit-sell-page-sell-page-tsx": () => import("./../../../src/templates/openfit/SellPage/SellPage.tsx" /* webpackChunkName: "component---src-templates-openfit-sell-page-sell-page-tsx" */)
}

