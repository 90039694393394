module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MWKJXM7","includeInDevelopment":true,"gtmAuth":"rEID4Vgc7kuqTv73OZ6TFg","gtmPreview":"env-3","routeChangeEventName":"route_change","ignoredPages":["/cart/"],"defaultDataLayer":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
