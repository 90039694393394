import { TooltipTheme } from '@bbnb/openfit-frontend-shared';

const BREAKPOINTS = ['40em', '52em', '64em'];

type FontFamilyThemeConfig = {
  default: string;
  alternate: string;
  secondary?: string;
};

const openfitFont = `'Nunito Sans', sans-serif`;
const openfitSecondaryFont = `'Poppins', sans-serif`;

const openfitFontFamilyConfig: FontFamilyThemeConfig = {
  default: openfitFont,
  alternate: openfitFont,
  secondary: openfitSecondaryFont,
};

const openfitColors = {
  default: '#2b2738',
  blue800: '#212852',
  blue400: '#007EF2',
  blue200: '#CDEAE9',
  blueNF1: '#128FD0',
  blueNF2: '#067EBD',
  green800: '#008F5C',
  green400: '#14BB75',
  green200: '#CFE5C1',
  red800: '#F10000',
  red400: '#FF550C',
  red200: '#F9CDBA',
  yellow800: '#ED9622',
  yellow400: '#FFD03D',
  yellow200: '#F8F6D7',
  yellow100: '#F0F3E2',
  basic800: '#2B2738',
  basic700: '#373737',
  basic600: '#7D7E98',
  basic400: '#CACADA',
  basic200: '#F9F9FC',
  basic150: '#E8E9ED',
  basic100: 'white',
  gray: '#F7F7F8',
  gray2: '#C4C4C4',
  gray3: '#7B7B7B',
  gray4: '#E5E5E5',
  gray5: '#8F8F8F',
  ivory: '#F6F3ED',
  transparent: 'transparent',
  black: '#000',
  orangeHover: '#FF6E30',
  blueHover: '#3A9FFD',
  redHover: '#FF4040',
};

const typography = {
  heading1: {
    fontSize: ['26px', '26px', '36px'],
    lineHeight: ['36px', '36px', '44px'],
    fontWeight: 800,
  },
  heading2: {
    fontSize: ['20px', '20px', '32px'],
    lineHeight: ['24px', '24px', '36px'],
    fontWeight: 800,
  },
  heading3: {
    fontSize: ['20px', '20px', '26px'],
    lineHeight: ['24px', '24px', '36px'],
    fontWeight: 800,
  },
  heading4: {
    fontSize: ['20px'],
    lineHeight: ['24px'],
    fontWeight: 800,
  },
  default: {
    // Body - (Label in Figma is just Body with fontWeight: 600)
    fontSize: ['14px', '14px', '18px'],
    lineHeight: ['20px', '20px', '24px'],
    fontWeight: 400,
  },
  small: {
    // Secondary (Secondary Bold is same but with fontWeight: 600)
    fontSize: ['14px'],
    lineHeight: ['20px'],
    fontWeight: 400,
  },
  xSmall: {
    // Tertiary (Tertiary Bold is same but with fontWeight: 600)
    fontSize: ['12px'],
    lineHeight: ['16px'],
    fontWeight: 400,
  },
};

const openfitTheme = {
  fonts: openfitFontFamilyConfig,
  colors: openfitColors,
  select: {
    primary: openfitColors.blue400,
    secondary: openfitColors.basic600,
  },
  variantSwatch: {
    activeBackground: `#F0F6FB`, // One off color for swatch
  },
  priceViolator: {
    background: '#ffc702',
  },
  ratings: {
    unratedColor: '#dcdcdc',
    ratedColor: '#ffc702',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
  },
  typography,
  breakpoints: BREAKPOINTS,
  container: '61.25em',
  fontSizes: {
    large: '18px',
    default: '16px',
    small: '14px',
    xSmall: '12px',
  },
  tooltip: {} as TooltipTheme['tooltip'],
  link: {
    color: openfitColors.blue400,
    hover: openfitColors.blueHover,
  },
};

const ladderColors = {
  basic100: '#FFFFFF',
  basic200: '#F1F2F2',
  basic400: '#CCCEC8',
  basic600: '#77787B',
  basic700: '#4D4D4F',
  basic800: '#333132',
  basic900: '#231F20', // We using this?
  blue100: '#F4F6FC',
  blue300: '#C7CFF1',
  blue400: '#909FE3',
  blue500: '#5870D6',
  blue600: '#3653CE',
  blue700: '#2040C8',
  blue800: '#2D3978',
  red800: '#F10000',
  green800: '#20AC72',
  yellow400: '#FFC555',
};

const ladderFontFamilyConfig: FontFamilyThemeConfig = {
  default: 'Indivisible, sans-serif',
  alternate: "'Soehne Breit', sans-serif",
};

export const ladderTheme = {
  ...openfitTheme,
  fonts: ladderFontFamilyConfig,
  colors: {
    ...openfitTheme.colors,
    ...ladderColors,
  },
  priceViolator: {
    background: ladderColors.yellow400,
  },
  ratings: {
    unratedColor: '#dcdcdc',
    ratedColor: ladderColors.blue700,
  },
  select: {
    primary: ladderColors.blue700,
    secondary: ladderColors.basic600,
  },
  variantSwatch: {
    activeBackground: ladderColors.blue100,
  },
  fontSizes: {
    large: '20px',
    default: '18px',
    small: '14px',
    xSmall: '12px',
  },
  typography,
  link: {
    color: ladderColors.blue700,
    hover: ladderColors.blue500,
  },
};

type ThemeType = typeof openfitTheme & TooltipTheme;
type LadderThemeType = typeof ladderTheme;

type LadderThemeProp = { theme: LadderThemeType };

export { ThemeType, LadderThemeType, LadderThemeProp };

export default openfitTheme;

export const device = {
  small: {
    width: BREAKPOINTS[0],
  },
  medium: {
    width: BREAKPOINTS[1],
  },
  large: {
    width: BREAKPOINTS[2],
  },
};
