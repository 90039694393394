import { navigate } from 'gatsby';
import { doesWindowExist, getTimeZone } from '@bbnb/openfit-frontend-shared';

export function formatSavingsPercent(newPrice: number, oldPrice: number) {
  return Number(
    ((100 - (newPrice / oldPrice) * 100) / 100).toString().substring(0, 4)
  ).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 0,
  });
}

export function getHomepageUrl() {
  if (doesWindowExist()) {
    return window.location.origin;
  }

  return '/';
}

export const isRelativeUrl = (url: string) =>
  (url.startsWith('/') && !url.startsWith('//')) || url.startsWith('#');

export const navigateToUrl = (url: string) =>
  isRelativeUrl(url) ? navigate(url) : window.location.assign(url);

export const getTZ = getTimeZone;

export function isIframe() {
  try {
    return window.self !== window.parent;
  } catch (e) {
    return true;
  }
}
